.usps {

	@include e('list') {
		list-style: none;
		padding-bottom: rem-calc($section-padding-xs);
		text-align: center;

		li {
			font-size: rem-calc(32);
			line-height: rem-calc(46);

			@extend .font-headers;
		}
	}
}


@include media-breakpoint-up(lg) {
	.usps {

		@include e('list') {
			li {
				line-height: rem-calc(46);
			}
		}
	}
}