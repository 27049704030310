// Search form
.search-form {
	@extend .form-inline;
}
.search-form label {
	font-weight: normal;
	@extend .form-group;
}
.search-form .search-field {
	@extend .form-control;
}
.search-form .search-submit {
	@extend .btn;
	@extend .btn-secondary;
}


form {
	&.no-label {
		label {
			&.gfield_label {
				display: none !important;
			}
		}
		.ginput_container {
			margin: 0 !important;
		}
	}

	label {
		font-size: 14px;
	}

	input {
		&[type='text'],
		&[type='email'],
		&[type='number'],
		&[type='password'],
		&[type='tel'] {
			@extend .input-default;
			@extend .form-control;
		}

		&[type='submit'],
		&[type='button'] {
			display: block;
			font-size: rem-calc(24);
			line-height: rem-calc(42);
			width: 100%;

			@extend .btn;
			@extend .btn-warning;
		}
	}

	select,
	textarea {
		@extend .input-default;
		@extend .form-control;
	}

	textarea {
		min-height: 100px;
	}
}

@include media-breakpoint-down(md) {
	form {
		font-size: 100%;
	}
}


@include media-breakpoint-up(md) {
	form {
		input {
			&[type='submit'],
			&[type='button'] {
				display: inline-block;
				width: auto;
			}
		}
	}

	.sidebar {
		form {
			input {
				&[type='submit'],
				&[type='button'] {
					display: block;
					width: 100%;
				}
			}
		}
	}
}