.home {
	.reviews {
		background-color: $purple;
		position: relative;

		-webkit-clip-path: polygon(0 2%, 100% 0%, 100% 98%, 0% 100%);
		clip-path: polygon(0 2%, 100% 0%, 100% 98%, 0% 100%);

		&:before {
			background: url('../images/bg-reviews.jpg') center center no-repeat fixed;
			background-size: cover;
			content: '';
			display: block;
			height: 100%;
			opacity: 0.3;
			position: absolute; top: 0; left: 0;
			width: 100%;
			z-index: -1;
		}

		@include e('title') {
			color: $white;
			text-align: center;
		}

		@include e('slideshow') {
			margin: 0 0 rem-calc($section-padding-lg);

			@include e('slide') {
				opacity: 0.6;
				transition-timing-function: linear;
				transition: opacity 0.3s, transform 0.3s;
				transform: scale(0.8);

				&.is-selected {
					opacity: 1;
					transform: scale(1);
				}

				@include e('review') {
					background: $white;
					height: 100%;
					padding: rem-calc($section-padding-xs);

					@extend .card;

					@include e('quote') {
						font-style: italic;
						padding: 0 25px;
						position: relative;
					}
				}
			}

		}
	}
}

@include media-breakpoint-up(lg) {
	.home {
		.reviews {
			-webkit-clip-path: polygon(0 10%, 100% 0%, 100% 90%, 0% 100%);
			clip-path: polygon(0 10%, 100% 0%, 100% 90%, 0% 100%);
		}
	}
}