// Grid settings
$main-sm-columns:       12;
$sidebar-sm-columns:    4;

$enable-rounded:            true;
$enable-shadows:            false;
$enable-gradients:          false;
$enable-transitions:        true;
$enable-hover-media-query:  false;
$enable-grid-classes:       true;
$enable-print-styles:       true;

// Colors
$brand-primary:         #27ae60;
$pink:					#f40087;
$white:					#fff;
$black:					#262422;
$color-font-main:		#444444;
$purple:				#613d7c;

$body-bg:				$white;
$footer-bg:				#282828;

$section-padding-xs:	20;
$section-padding-sm:	30;
$section-padding-md:	60;
$section-padding-lg:	80;
$section-padding-xl:	140;
$section-padding-xxl:	160;

$font-base-size:		18;
$font-size-lg:			1.25rem;
$font-size-sm:			.9rem;
$font-size-xs:			.8rem;

$font-weight-xlight:	100;
$font-weight-light:		300;
$font-weight-normal:	500;
$font-weight-bold:		700;
$font-weight-xbold:		900;


$container-max-widths: (
sm: 540px,
md: 720px,
lg: 960px,
xl: 1140px,
xxl: 1500px,
);


/*$grid-breakpoints: (
xs: 0,
sm: 576px,
lg: 992px,
xl: 1200px,
xxl: 1560px
) !default;*/

@mixin e($element) {
	&__#{$element} {
		@content;
	}
}
@mixin m($modifier) {
	&--#{$modifier} {
		@content;
	}
}

@function rem-calc($pixels) {
	@return #{$pixels/18}rem;
}

@mixin calc($property, $expression) {
	#{$property}: -webkit-calc(#{$expression});
	#{$property}: calc(#{$expression});
}

@function rem-calc($target) {
    $max: length($target);
    $values: ();
    $context: $font-base-size;
    @for $i from 1 through $max {
        $x: 0;
        $x: (nth($target, $i) / $context) * 1rem;
        @if abs($x) == 0 {
            $values: append($values, 0);
        }
        @else {
            $values: append($values, $x);
        }
    }
    @return $values;
}


@font-face{
    font-family: 'geomanist-thin-webfont';
    src:	url('../fonts/geomanist-thin-webfont.eot');
    src:	url('../fonts/geomanist-thin-webfont.eot?#iefix') format('embedded-opentype'),
		url('../fonts/geomanist-thin-webfont.woff') format('woff'),
		url('../fonts/geomanist-thin-webfont.ttf') format('truetype'),
		url('../fonts/geomanist-thin-webfont.svg#webfont') format('svg');
    font-weight: normal;
    font-style: normal;
}

@font-face{
    font-family: 'geomanist-light-webfont';
    src:	url('../fonts/geomanist-light-webfont.eot');
    src:	url('../fonts/geomanist-light-webfont.eot?#iefix') format('embedded-opentype'),
		url('../fonts/geomanist-light-webfont.woff') format('woff'),
		url('../fonts/geomanist-light-webfont.ttf') format('truetype'),
		url('../fonts/geomanist-light-webfont.svg#webfont') format('svg');
    font-weight: normal;
    font-style: normal;
}

@font-face{
    font-family: 'geomanist-regular-webfont';
    src:	url('../fonts/geomanist-regular-webfont.eot');
    src:	url('../fonts/geomanist-regular-webfont.eot?#iefix') format('embedded-opentype'),
		url('../fonts/geomanist-regular-webfont.woff') format('woff'),
		url('../fonts/geomanist-regular-webfont.ttf') format('truetype'),
		url('../fonts/geomanist-regular-webfont.svg#webfont') format('svg');
    font-weight: normal;
    font-style: normal;
}

@font-face{
    font-family: 'geomanist-medium-webfont';
    src:	url('../fonts/geomanist-medium-webfont.eot');
    src:	url('../fonts/geomanist-medium-webfont.eot?#iefix') format('embedded-opentype'),
		url('../fonts/geomanist-medium-webfont.woff') format('woff'),
		url('../fonts/geomanist-medium-webfont.ttf') format('truetype'),
		url('../fonts/geomanist-medium-webfont.svg#webfont') format('svg');
    font-weight: normal;
    font-style: normal;
}

@font-face{
    font-family: 'geomanist-bold-webfont';
    src:	url('../fonts/geomanist-bold-webfont.eot');
    src:	url('../fonts/geomanist-bold-webfont.eot?#iefix') format('embedded-opentype'),
		url('../fonts/geomanist-bold-webfont.woff') format('woff'),
		url('../fonts/geomanist-bold-webfont.ttf') format('truetype'),
		url('../fonts/geomanist-bold-webfont.svg#webfont') format('svg');
    font-weight: normal;
    font-style: normal;
}

@font-face{
	font-family: 'PF-Din-Text-Comp-Pro-regular';
	src:	url('../fonts/pfdintextcomppro-regular.eot?#ie');
	src:	url('../fonts/pfdintextcomppro-regular.eot?#ie') format('embedded-opentype'),
		url('../fonts/pfdintextcomppro-regular.woff') format('woff'),
		url('../fonts/pfdintextcomppro-regular.ttf') format('truetype'),
		url('../fonts/pfdintextcomppro-regular.svg#pt-sans') format('svg');
	font-weight: normal;
	font-style: normal;
}

@font-face{
	font-family: 'PF-Din-Text-Comp-Pro-medium';
	src:	url('../fonts/pfdintextcomppro-medium.woff') format('woff'),
		url('../fonts/pfdintextcomppro-medium.ttf') format('truetype');
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: 'PF-Din-Text-Cond-Pro-regular';
	src:	url('../fonts/pfdintextcondpro-regular.eot?#ie');
	src:	url('../fonts/pfdintextcondpro-regular.eot?#ie') format('embedded-opentype'),
		url('../fonts/pfdintextcondpro-regular.woff') format('woff'),
		url('../fonts/pfdintextcondpro-regular.ttf') format('truetype'),
		url('../fonts/pfdintextcondpro-regular.svg#pt-sans') format('svg');
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: 'PF-Din-Text-Cond-Pro-medium';
	src:	url('../fonts/pfdintextcondpro-medium-webfont.eot?#ie');
	src:	url('../fonts/pfdintextcondpro-medium-webfont.eot?#ie') format('embedded-opentype'),
		url('../fonts/pfdintextcondpro-medium-webfont.ttf') format('truetype'),
		url('../fonts/pfdintextcondpro-medium-webfont.svg#pt-sans') format('svg');
	font-weight: bold;
	font-style: normal;
}

@font-face {
	font-family: 'PF-Din-Text-Cond-Pro-light';
	src:	url('../fonts/pfdintextcondpro-light-webfont.eot?#ie');
	src:	url('../fonts/pfdintextcondpro-light-webfont.eot?#ie') format('embedded-opentype'),
		url('../fonts/pfdintextcondpro-light-webfont.woff') format('woff'),
		url('../fonts/pfdintextcondpro-light-webfont.ttf') format('truetype');
	font-weight: normal;
	font-style: normal;
}