.blogpost {
	@include e('title') {
		font-size: 1.6rem;

		@extend .text-truncate;
	}

	@include e('img') {
		border-radius: 6px;

		@extend .img-fluid;
	}

	@include e('content') {
		margin-bottom: 2rem;
		height: 70px;
		overflow: hidden;
	}
}

@include media-breakpoint-up(md) {
	.blogpost {

		@include e('content') {
			height: 110px;
		}
	}
}