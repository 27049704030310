.arrangements {
	background: #010101;
	color: $white;
	position: relative;

	@include gradient-directional(#ef3f70, #510b76, 45deg);

	@include e('title') {
		color: $white;
		padding-bottom: rem-calc($section-padding-xs / 2);
		text-align: center;
	}

	@include e('subtitle') {
		color: $white;
		padding-bottom: rem-calc($section-padding-xs);
		text-align: center;
	}

	@include e('rating') {
		color: $white;
		padding-bottom: rem-calc($section-padding-xs);
		text-align: center;

		i {
			margin-top: rem-calc($section-padding-xs / 2);
			font-size: rem-calc(30);
		}
	}

	@include e('item') {
		border: none;
		box-shadow: 0 5px 10px rgba(0, 0, 0, 0.3);
		color: $black;
		margin: 0 0 20px;
		text-align: center;
		transform: translate3d(0, 0, 0);
		transition: all 0.3s linear;

		@extend .card;

		@include e('img') {
			@extend .img-fluid;
			@extend .card-img-top;
		}

		&:hover {
			transform: translate3d(0, -10px, 0);
		}

		@include e('content') {
			@extend .card-body;
		}

		@include e('title') {
			color: $black;
			font-size: 18px;
			margin-bottom: 1.25rem;
		}
	}
}

.home {
	.arrangements {
		position: relative;

		video {
			background: url('../images/bg-header.jpg') no-repeat;
			background-size: cover;
			display: block;
			height: auto;
			position: fixed; top: 50%; left: 50%;
			min-width: 100%;
			min-height: 100%;
			opacity: 0.3;
			transform: translateX(-50%) translateY(-50%);
			transition: all 0.5s linear;
			width: auto;
			z-index: -100;
		}
	}
}

.mm-wrapper_opened {
	.home {
		.arrangements {
			video {
				display: none;
				opacity: 0;
				visibility: hidden;
			}
		}
	}
}


.home,
.archive {
	.arrangements {
		-webkit-clip-path: polygon(0 0, 100% 0, 100% 98%, 0% 100%);
		clip-path: polygon(0 0, 100% 0, 100% 98%, 0% 100%);


		@include e('title') {
			font-size: 3.6rem;
		}

		@include e('subtitle') {
			font-size: 2rem;
		}
	}
}

@include media-breakpoint-down(md) {
	.home {
		.arrangements {
			position: relative;

			/*			&:after {
							background: url('../images/icon-swipe-white.png');
							content: '';
							display: block;
							height: 24px;
							margin-left: -24px;
							position: absolute; bottom: 30px; left: 50%;
							width: 48px;
						}

						.row {
							display: -webkit-box;
							overflow: hidden;
							overflow-x: auto;
							white-space: nowrap;

							-webkit-overflow-scrolling: touch;
							-ms-overflow-style: -ms-autohiding-scrollbar;
						}*/
		}
	}
}

@include media-breakpoint-up(md) {
	.home,
	.archive {
		.arrangements {
			@include e('title') {
				padding-bottom: rem-calc($section-padding-xs);
			}

			@include e('item') {
				@include e('title') {
					font-size: 1.25rem;
				}
			}
		}
	}

	.home {
		.arrangements {

			padding: 0 !important;

			.row-vertical-align {
				min-height: 80vh;
			}
			/*			&:before {
							background: url('../images/bg-header.jpg') center center no-repeat fixed;
							background-size: cover;
							content: '';
							display: block;
							height: 100%;
							opacity: 0.4;
							position: absolute; top: 0; left: 0;
							width: 100%;
							z-index: -1;
						}*/
		}
	}

}

@include media-breakpoint-up(lg) {
	.home,
	.archive {
		.arrangements {
			-webkit-clip-path: polygon(0 0, 100% 0, 100% 90%, 0% 100%);
			clip-path: polygon(0 0, 100% 0, 100% 90%, 0% 100%);
		}
	}

}

@include media-breakpoint-up(xl) {
	.home {
		.arrangements {}
	}
}


@include media-breakpoint-up(xxl) {
	.home {
		.arrangements {}
	}
}
