.btn {
	border-radius: rem-calc(6);
	color: $white;
	cursor: pointer;
	font-family: 'PF-Din-Text-Cond-Pro-medium';
}

.btn-pink {
	@extend .btn;

	@include button-variant($white, $pink, $pink);
}

@include media-breakpoint-down(md) {

	.btn {
		font-size: 100%;
	}
}