.galleries {
	padding: $section-padding-xs 0;

	@include e('title') {
		padding-bottom: rem-calc($section-padding-xs);
		text-align: center;
	}

	.type-fotoalbums {
		transition: all 0.2s linear;
		text-align: center;

		@include make-col(4);

		&:nth-of-type(even) {
			transform: rotate(-2deg);
		}
		&:nth-of-type(odd) {
			transform: rotate(3deg);
		}
		&:nth-of-type(3n+2) {
			transform: rotate(-3deg);
		}
		&:nth-of-type(3n+3) {
			transform: rotate(5deg);
		}
		&:nth-of-type(4n+4) {
			transform: rotate(-5deg);
		}
		&:nth-of-type(4n+5) {
			transform: rotate(1deg);
		}
		&:hover {
			transform: rotate(0);
		}
	}
}

.gallery {
	margin-bottom: 1rem;

	@include make-row();


	.gallery-item {
		/*max-width: 170px;*/
		transition: all 0.2s linear;
		text-align: center;

		@include make-col(4);
		@extend .d-flex;

		&:nth-of-type(even) {
			transform: rotate(-2deg);
		}
		&:nth-of-type(odd) {
			transform: rotate(3deg);
		}
		&:nth-of-type(3n+3) {
			transform: rotate(5deg);
		}
		&:nth-of-type(4n+4) {
			transform: rotate(-5deg);
		}
		&:nth-of-type(4n+5) {
			transform: rotate(-6deg);
		}
		&:hover {
			transform: rotate(0);
		}

		.attachment-thumbnail {
			@extend .polaroid;
			@extend .img-fluid;
		}
	}
}

.polaroid {
	background: $white;
    box-shadow: 0 3px 6px rgba(0, 0, 0, .25);
	color: #333;
	display: inline-block;
    font-size: 16px;
	line-height: 20px;
	margin: 0;
    padding: 10px 10px 20px;
	text-align: center;
    text-decoration: none;
    transition: all 0.1s linear;

	img {
		clear: both;
		margin: 0 0 10px;

		@extend .img-fluid;
	}
}

@include media-breakpoint-up(md) {
	.gallery {
		.gallery-item {
			@include make-col(2);
		}
	}
	.galleries {
		.type-fotoalbums {
			@include make-col(3);
		}
	}
	.page-template-template-column-right {
		.galleries {
			.type-fotoalbums {
				@include make-col(4);
			}
		}
	}
}

@include media-breakpoint-up(lg) {
	.galleries {
		.type-fotoalbums {
			@include make-col(2);
		}
	}
}