html {
	font-size: 62.5%
}

body {
	background: $body-bg;
	color: $color-font-main;
	font-size: 14px;
	line-height: 20pt;
	margin: 0;
	overflow-x: hidden;
	padding: 0;
	position: relative;
	width: 100%;

	-webkit-font-smoothing: antialiased;

	@extend .font-default;
}

a {
	color: $pink;
	transition: all 0.3s linear;

	&:hover {
		color: $pink;
		text-decoration: none;
	}

	&:focus {
		outline: none;
	}
}

button {
	&:focus {
		outline: none;
	}
}

.np {
	padding: 0 !important;
}

.input-default {
	background: #f8f8f8;
	border: 1px solid #ebebeb;
	font-size: 14px;
	line-height: 2;
	min-height: 32px;
}

.font-default {
	font-family: 'geomanist-regular-webfont';
}

.font-headers,
.font-navigation {
	font-family: 'PF-Din-Text-Cond-Pro-medium';
}

h1, h2, h3, h4, h5, h6 {
	color: $purple;

	@extend .font-headers;
}

table {
	th,
	td {
		white-space: nowrap;
	}
}

.flickity-viewport {
	transition: height 0.2s;
}

@include media-breakpoint-down(md) {
	.input-default {
		font-size: 100%;
	}
}

@include media-breakpoint-up(md) {
	html {
		font-size: 80%
	}
	body {
		/*background: $body-bg url('../images/bg-white-002.jpg') center center no-repeat fixed;*/
		font-size: 16px;
		line-height: 22pt;

		&.home,
		&.dansschool-ede {
			background: $body-bg url('../images/bg-white-001.jpg') center center no-repeat fixed;
		}
	}
}

@include media-breakpoint-up(lg) {
	html {
		font-size: 90%
	}
	body {
		padding-top: 80px;
	}
}

@include media-breakpoint-up(xl) {
	html {
		font-size: 100%
	}
	body {
		padding-top: 110px;
	}
}