&.tc-extra-product-options {
	&.tm-element-ul-checkbox {
		.tmcp-field-wrap {
			label {
				background: url('../images/icon-unchecked.png') 0 50% no-repeat;
				cursor: pointer;
				padding: 0 0 0 20px;

				input {
					&.tmcp-checkbox {
						display: none;

						&:checked ~ label {
							background: url('../images/icon-checked.png') 0 50% no-repeat;
							font-weight: bold;
						}
					}
				}
			}
		}
	}
}
.tm-epo-style.square {
/*	height: 24px;
	width: 24px;*/

}

.tmaccordion,
.tm-collapse {
	background: #f1f1f1;
	border: 1px solid #ebebeb;
	border-radius: 3px;
}