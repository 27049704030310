.page,
.blog,
.archive,
.single,
.woocommerce {
	&:not(.page-template-template-column-right):not(.single-dansvormen) {
		.section {
			padding: rem-calc($section-padding-md * 1.7) 0;

			@include e('title') {
				padding-bottom: rem-calc($section-padding-md);
			}
		}
	}

	.section {
		padding: rem-calc($section-padding-md) 0;

		@include e('title') {
			padding-bottom: rem-calc($section-padding-xs);
		}
	}
}

.page,
.blog,
.archive,
.single,
.woocommerce {
	&:not(.page-template-template-column-right):not(.single-dansvormen) {
		.section {
			@include e('title') {
				font-size: 3rem;
				text-align: center;
			}
		}
	}
}

@include media-breakpoint-up(md) {
	.page,
	.blog,
	.archive,
	.single,
	.woocommerce {
		&:not(.page-template-template-column-right):not(.single-dansvormen) {
			.section {
				padding: rem-calc($section-padding-md) 0;

				@include e('title') {
					padding-bottom: rem-calc($section-padding-md);
				}

			}
		}
		.section {
			padding: 0;
		}
	}
}

@include media-breakpoint-up(lg) {
	.page,
	.blog,
	.archive,
	.single,
	.woocommerce {
		&:not(.page-template-template-column-right):not(.single-dansvormen) {
			.section {
				padding: rem-calc($section-padding-lg) 0;

				@include e('title') {
					padding-bottom: rem-calc($section-padding-lg);
				}
			}
		}
	}


}

@include media-breakpoint-up(xl) {
	.page,
	.blog,
	.archive,
	.single,
	.woocommerce {
		&:not(.page-template-template-column-right):not(.single-dansvormen) {
			.section {
				padding: rem-calc($section-padding-xl) 0;
			}
		}
	}
}
