footer {
	background: $footer-bg;
	border: none;
	color: $white;
	clear: both;
	padding: rem-calc($section-padding-xs) 0;

	-webkit-clip-path: polygon(0 5%, 100% 0, 100% 100%, 0% 100%);
	clip-path: polygon(0 5%, 100% 0, 100% 100%, 0% 100%);

	h3 {
		color: $white;
	}

	ul {
		&.menu {
			list-style: none;
			margin: 0;
			padding: 0;

			li {
				a {
					color: $white;
				}
			}
		}
	}

}

.footer-bottom {
	background: #1b1b1b;
	border: none;
	color: $white;
	padding: 1rem 0;
}

@include media-breakpoint-up(sm) {
	footer {
		padding: rem-calc($section-padding-sm) 0;
	}
}

@include media-breakpoint-up(md) {
	footer {
		padding: rem-calc($section-padding-md) 0;
	}
}

@include media-breakpoint-up(lg) {
	footer {
		padding: rem-calc($section-padding-lg) 0;

		-webkit-clip-path: polygon(0 20%, 100% 0, 100% 100%, 0% 100%);
		clip-path: polygon(0 20%, 100% 0, 100% 100%, 0% 100%);
	}
}

@include media-breakpoint-up(xl) {
	footer {
		padding: rem-calc($section-padding-xl) 0;
	}
}

@include media-breakpoint-up(xxl) {
	footer {
		padding: rem-calc($section-padding-xxl) 0;
	}
}