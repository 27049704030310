.wcs-timetable {
	.wcs-btn {
		@extend .btn;
	}
	
	@include e('wrapper') {
		.wcs-filter-toggler {
			@extend .btn;
			@extend .btn-warning;
		}
	}

	@include e('tabs-nav') {
		&:before {
			display: none !important;
		}

		li {
			border-radius: 6px;

			&.active {
				&:after {
					display: none;
				}
			}
		}
	}

	@include e('tabs-nav-item') {
		&:before {
			display: none !important;
		}

		a {
			cursor: pointer;
		}
	}
}

@include media-breakpoint-down(lg) {

	.wcs-timetable {
		margin-top: 1rem;

		@include e('wrapper') {
			.wcs-filter-toggler {}
		}

		@include e('tabs-nav') {
			height: 90px;
			white-space: nowrap;
			overflow: hidden;
			overflow-x: auto;

			-webkit-overflow-scrolling: touch;
			-ms-overflow-style: -ms-autohiding-scrollbar;
		}

		@include e('tabs-nav-item') {
			/*display: block;*/
			/*width: 100%;*/

			&:before {
				/*display: none !important;*/
			}

			a {
				/*display: block;*/
				/*width: 100%;*/
			}
		}
	}
}