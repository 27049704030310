// Grid system
.main {
	position: relative;

/*	&:after {
		background: #f8f8f8;
		content: '';
		display: block;
		height: 175px;
		position: absolute; top: 100px;
		transform: skew(0, 4deg);
		width: 100%;
		z-index: 0;
	}*/
}

.home {
	.main {
		&:after {
			display: none;
		}
	}
}