.cart-total-number {
	opacity: 0;
	transition: all 0.6s linear;
	visibility: hidden;
}
nav {
	&.nav-primary {
		background: $white;
		box-shadow: 0px 3px 10px 0px rgba(0,0,0,0.3);
		min-height: 60px;

		.navbar-collapse {
			padding: 60px 0 0;
		}

		.navbar-brand {
			display: block;
			position: absolute; left: 0; top: 0;
			width: 235px;
			z-index: 10;

			span {
				background: url('../images/logo2.png') center center no-repeat;
				background-size: cover;
				display: block;
				height: 97px;
				position: absolute; top: 0; left: 0;
				text-indent: -9999px;
				width: 235px;

				-webkit-clip-path: polygon(0 0, 100% 0, 55.56% 62.85%, 0% 100%);
				clip-path: polygon(0 0, 100% 0, 55.56% 62.85%, 0% 100%);
			}
		}

		ul {
			&.navbar-nav,
			&.sub-menu {
				display: block;
				list-style: none;
				margin: 0;
				padding: 0;
				text-align: left;

				li {
					display: block;

					&.link-basket {
						i {
							position: relative;
						}

						span {
							&.cart-total-number {
								background: #f60;
								color: $white;
								border-radius: 50%;
								display: block;
								font-size: 9px;
								height: 16px;
								line-height: 16px;
								opacity: 1;
								position: absolute; top: -10px; right: -10px;
								text-align: center;
								visibility: visible;
								width: 16px;
							}
						}
					}

					&.active,
					&.hover,
					&:hover {
						> a {
							color: $pink;
						}
					}

					&.hover,
					&:hover {
						> .sub-menu {
							display: block;
							opacity: 1;
							visibility: visible;
						}
					}

					&.menu-item-has-children {
						position: relative;

						&:before {
							content: '\f078';
							border-left: 1px solid #eee;
							display: inline-block;
							font-family: FontAwesome;
							font-size: 10px;
							line-height: 30px;
							height: 32px;
							position: absolute; right: 0;
							text-align: center;
							width: 32px;
							z-index: 100;
						}
					}

					a {
						color: $black;
						font-size: rem-calc(30);
						display: block;
						line-height: rem-calc(60);

						@extend .font-navigation;
					}
				}
			}

			&.sub-menu {
				display: none;
				opacity: 0;
				visibility: hidden;
				margin-left: 10px;
			}
		}
	}
}

.page-header {
	height: 110px;
	padding: rem-calc($section-padding-sm) 0;
	position: relative;
	z-index: 1;

	@include gradient-directional(#ef3f70, #510b76, 45deg);

	-webkit-clip-path: polygon(0 0, 100% 0, 100% 90%, 0% 100%);
	clip-path: polygon(0 0, 100% 0, 100% 90%, 0% 100%);

	@include e('title') {
		color: $white;
		line-height: 80px;
	}
}

@include media-breakpoint-down(lg) {

	.navbar-toggler {
		border-radius: 0;
		display: block;
		height: 50px;
		margin: 0;
		outline: none;
		padding: 0;
		position: absolute; top: 10px; right: 10px;
		width: 40px;
		z-index: 100;

		span {
			background: $purple;
			display: block;
			position: absolute;
			height: 2px;
			width: 50%;
			opacity: 1;
			transform: rotate(0deg);
			transition: .25s ease-in-out;

			&:nth-child(even) {
				left: 50%;
			}

			&:nth-child(odd) {
				left:0px;
			}

			&:nth-child(1), &:nth-child(2) {
				top: 12px;
			}

			&:nth-child(3), &:nth-child(4) {
				top: 18px;
			}

			&:nth-child(5), &:nth-child(6) {
				top: 24px;
			}
		}

	}

	.mm-wrapper_opened {
		.navbar-toggler {
			span {

				&:nth-child(1),
					&:nth-child(6) {
					transform: rotate(45deg);
				}

				&:nth-child(2),
					&:nth-child(5) {
					transform: rotate(-45deg);
				}

				&:nth-child(1) {
					left: 2px;
					top: 12px;
				}

				&:nth-child(2) {
					left: calc(50% - 4px);
					top: 12px;
				}

				&:nth-child(3) {
					left: -50%;
					opacity: 0;
				}

				&:nth-child(4) {
					left: 100%;
					opacity: 0;
				}

				&:nth-child(5) {
					left: 2px;
					top: 24px;
				}

				&:nth-child(6) {
					left: calc(50% - 4px);
					top: 24px;
				}
			}
		}
	}
}

@include media-breakpoint-up(md) {
	nav {
		&.nav-primary {
			.navbar-collapse {
				padding: 46px 0 0;
			}
		}
	}
}

@include media-breakpoint-up(lg) {

	nav {
		&.nav-primary {
			padding: 0;
			position: fixed; top: 0; left: 0;
			width: 100%;
			z-index: 100;

			.navbar-collapse {
				padding: 0;
			}

			.navbar-brand {
				width: 316px;

				&:before {
					background: $pink;
					content: '';
					display: block;
					position: absolute; left: 0; top: 80px;
					width: 180px;
					height: 90px;
					z-index: -1;

					-webkit-clip-path: polygon(0 0, 100% 0, 0% 100%);
					clip-path: polygon(0 0, 100% 0, 0% 100%);
				}
				&:after {
					background: #f6f6f6;
					content: '';
					display: block;
					position: absolute; left: 180px; top: 0;
					width: 197px;
					height: 80px;
					z-index: -1;

					-webkit-clip-path: polygon(0 0, 100% 0, 0% 100%);
					clip-path: polygon(0 0, 100% 0, 0% 100%);
				}

				span {
					height: 128px;
					position: absolute; top: 0; left: 0;
					width: 318px;
				}
			}

			.navbar-collapse {
				justify-content: flex-end;
			}

			ul {
				&.navbar-nav,
				&.sub-menu {
					display: inline-block;
					list-style: none;
					margin: 0;
					padding: 0;
					text-align: right;

					li {
						display: inline-block;
						position: relative;

						&.hover,
						&:hover {
							> .sub-menu {
								opacity: 1;
								transform: translate3d(0, 0, 0);
								visibility: visible;
							}
						}

						&.menu-item-has-children {
							position: relative;

							&:before {
								display: none;
							}
						}

						a {
							display: block;
							font-size: 1.3rem;
							line-height: 80px;
							padding: 0 15px;
							white-space: nowrap;
						}
					}
				}

				&.navbar-nav {
					> li {
						&.menu-item-has-children {
							&:last-child {
								ul.sub-menu {
									left: auto;
									right: 0;

									li {
										&.menu-item-has-children {
											> a {
												&:after {
													display: none;
												}
												&:before {
													content: '\2039 \00a0';
													display: inline-block;
												}
											}
										}
										a {
											text-align: right;
										}

										ul.sub-menu {
											right: 100%;
										}
									}
								}
							}
						}
					}
				}

				&.sub-menu {
					background: $white;
					border-radius: 0 0 4px 4px;
					position: absolute; top: 100%; left: 0;
					transform: translate3d(0, 10px, 0);
					transition: all 0.3s linear;
					text-align: center;
					z-index: 100;

					.sub-menu {
						border-radius: 4px;
						top: 0; left: 100%;
						transform: translate3d(-10px, 0, 0);
					}


					li {
						display: block;

						&.menu-item-has-children {
							> a {
								&:after {
									content: '\00a0 \203A';
									display: inline-block;
								}
							}
						}

						a {
							line-height: 55px;
							text-align: left;
						}
					}
				}
			}

		}
	}

	.page-header {
		height: rem-calc(340);
		margin: rem-calc(-65) 0 rem-calc(80);
		padding: rem-calc($section-padding-md) 0;
		position: relative;

		-webkit-clip-path: polygon(0 0, 100% 0, 100% 80%, 0% 100%);
		clip-path: polygon(0 0, 100% 0, 100% 80%, 0% 100%);

		@include e('title') {
			color: $white;
			line-height: rem-calc(270);
			font-size: rem-calc(60);
			padding-bottom: rem-calc($section-padding-xs);

		}
	}

}

@include media-breakpoint-up(xl) {

	nav {
		&.nav-primary {
			.navbar-brand {
				width: 432px;

				&:before {
					position: absolute; left: 0; top: 110px;
					width: 240px;
					height: 148px;
				}
				&:after {
					position: absolute; left: 240px; top: 0;
					width: 432px;
					height: 110px;
				}

				span {
					height: 175px;
					position: absolute; top: 0; left: 0;
					width: 432px;
				}
			}

			ul {
				&.navbar-nav {
					li {
						a {
							line-height: 110px;
						}
					}
				}
				&.sub-menu {
					li {
						a {
							line-height: 55px;
						}
					}
				}
			}

		}
	}

}