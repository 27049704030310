.woocommerce {
	&.archive {
		.main {
			#sidebar {
				@include make-col-ready();
			}
			#primary {
				@include make-col-ready();
				@include make-col(12);
			}
		}
	}

	.col-product {
		@include make-col-ready();
		@include make-col(6);
	}

	.button {
		@extend .btn;

		&.single_add_to_cart_button {
			line-height: 1.6;

			@extend .btn-warning;
		}
	}

	form {
		.form-row {
			input {
				&.input-text {
					line-height: 2;
				}
			}
		}
	}
	span {
		&.onsale {
			background-color: #f60;
			color: $white;
			display: inline-block;
			height: 32px;
			line-height: 32px;
			min-height: 32px;
			min-width: 32px;
			padding: 0;
			width: 32px;
			text-indent: -9999px;

			&:before {
				content: "\f153";
				font-family: FontAwesome;
				font-style: normal;
				font-weight: normal;
				text-decoration: inherit;
				/*--adjust as necessary--*/
				font-size: 14px;
				position: absolute; top: 0; left: 0;
				text-indent: 0;
				height: 32px;
				width: 32px;
				text-align: center;
			}
		}
	}

	.related {
		&.products {
			clear: both;

			h2 {
				margin: 0 0 2rem;
			}
		}
	}

	.product_meta {
		.posted_in,
		.tagged_as {
			display: block;
		}
	}

	.woocommerce-notice {
		@extend .alert;

		&.woocommerce-notice--success {
			@extend .alert-success;
		}
	}

	table {
		&.woocommerce-table {
			@extend .table-responsive;
		}
	}

	ul {
		&.woocommerce-order-overview {
			@extend .nav-justified;
			@extend .list-unstyled;
		}

		&.product-categories {
			@extend .list-unstyled;
		}
	}

	.products {
		.product {
			margin-bottom: 2rem;

			@extend .card;

			img {
				@extend .card-img-top;
			}

			h2 {
				font-size: 1em;
				margin: 0;
				min-height: 50px;
				padding: 10px;
				text-align: center;
			}

			.price {
				display: block;
				font-weight: 400;
				font-size: .857em;
				margin-bottom: .5em;
				padding: 0 10px;
				text-align: right;

				del {
					color: $black;
					display: inline-block;
				}
				ins {
					display: inline-block;
				}
			}

			.button {
				margin: 10px;
			}

			.added_to_cart {
				&.wc-forward {
					margin: 0 10px 10px;

					@extend .btn;
					@extend .btn-success;
				}
			}
		}
	}

	.woocommerce-MyAccount-navigation {
		ul {
			@extend .nav;
			@extend .flex-column;
			@extend .nav-pills;

			li {
				margin: 0 0 5px;

				&.is-active {
					a {
						@extend .btn-danger;
					}
				}

				a {
					@extend .btn;
					@extend .btn-secondary;
				}
			}
		}
	}
}

@include media-breakpoint-up(md) {
	.woocommerce {
		&.archive {
			.main {

				#sidebar {
					@include make-col-ready();
					@include make-col(3);
				}
				#primary {
					@include make-col(9);
				}


			}

		}
		&.single-product {
			.col-product {
				@include make-col(3);
			}
		}

		.col-product {
			@include make-col(4);
		}
	}


	span {
		&.onsale {
			left: 10px; top: 10px;
		}

	}

}

@include media-breakpoint-up(lg) {
	.woocommerce {}

}

@include media-breakpoint-up(xl) {
	.woocommerce {}
}


@include media-breakpoint-up(xxl) {
	.woocommerce {}
}
