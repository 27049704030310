.sidebar {
	background: $white;
	border-radius: rem-calc(10);
	box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1);
	margin: 0;
	padding: rem-calc(20);
	position: relative;
	z-index: 50;

	@include e('title') {
		color: $purple;
		font-size: 1.7rem;
		padding: rem-calc(10) 0 rem-calc(20);
	}

	@include e('widget') {
		margin-bottom: 2rem;

		&:last-of-type {
			margin-bottom: 0;
		}
	}
}

@include media-breakpoint-up(md) {
	.main-sidebar {
		margin-top: -90px;
	}
}

@include media-breakpoint-up(lg) {
	.main-sidebar {
		margin-top: -160px;
	}
}